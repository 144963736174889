import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2';
import { AccountService } from '../providers/account.service';

const ROLES = {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    LEARNER: 'learner',
};

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService,
        private accountService: AccountService
    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: window.location.origin + state.url,
            });
        }

        // Get the roles required from the route.
        const requiredRoles = route.data.roles;
        let keyCloakInstance: any = this.keycloak.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let clientId = keyCloakInstance.clientId;
        let userRole = '';
        if (parseToken?.resource_access?.webapp1) {
            userRole = parseToken?.resource_access?.webapp1.roles[0];
        }

        this.accountService.attributes = keyCloakInstance.profile.attributes;
        let ma_don_vi = this.accountService.getAuthMetadata().ma_don_vi;
        if (ma_don_vi != '103') {
            await Swal.fire('Tài khoản không được cấp phép!', '', 'error');
            this.keycloak.logout();
            return false;
        }

        return true;

        // Allow the user to to proceed if no additional roles are required to access the route.
        // if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
        //   return true;
        // }

        // // Allow the user to proceed if all the required roles are present.
        // return requiredRoles.every((role) => this.roles.includes(role));
    }
}
