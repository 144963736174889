import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

export interface ItemsIds {
    code: string;
    title: string;
    id: number;
    ids: Array<any>;
    flag: string;
    data: any;
}

@Injectable({ providedIn: 'root' })
export class ItemsIdsServices {
    // Thông tin cần lưu trữ
    private itemIds: ItemsIds[] = [];

    // Để tạo và bắt sự kiện khi có cập nhật dữ liệu
    private subjectPostUpdated: Subject<ItemsIds[]> = new Subject<ItemsIds[]>();

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    getItemsIds() {
        return this.itemIds;
    }

    getItemsUpdateListener() {
        return this.subjectPostUpdated.asObservable();
    }

    async fetchItemIds(type: string, code: string = this.accountService.getCode()) {
        return await new Promise<ItemsIds[]>((reslove, reject) => {
            this.httpClient
                .post<ItemsIds[]>(
                    this.hostnameService.Hostname + '/api/get-items-ids',
                    { code: code, type: type },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    data.sort((e1, e2) => {
                        return e1['id'] - e2['id'];
                    });
                    data.forEach((d) => (d['code'] = code));
                    this.itemIds = data;
                    this.subjectPostUpdated.next(this.getItemsIds());
                    reslove(data);
                });
        });
    }

    async fetchItemIdsByCodesAndTypes(types: string[], codes: string[]) {
        return await new Promise<ItemsIds[]>((reslove, reject) => {
            this.httpClient
                .post<ItemsIds[]>(
                    this.hostnameService.Hostname + '/api/items-ids/get-items-by-codes-and-types',
                    { codes: codes, types: types },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    reslove(data);
                });
        });
    }

    async fetchItemIdsById(id: string) {
        return await new Promise<ItemsIds>((reslove, reject) => {
            this.httpClient
                .post<ItemsIds>(
                    this.hostnameService.Hostname + '/api/get-items-id',
                    { id: id },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    reslove(data);
                });
        });
    }

    async fetchItemIdByTitle(title: string, type: string, code: string = this.accountService.getCode()) {
        return await new Promise<ItemsIds>((reslove, reject) => {
            this.httpClient
                .post<ItemsIds>(
                    this.hostnameService.Hostname + '/api/get-item-by-title',
                    { code: code, title: title, type: type },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    reslove(data);
                });
        });
    }

    async fetchItemIdsTitles(type: string, code: string = this.accountService.getCode()) {
        return await new Promise<ItemsIds[]>((reslove, reject) => {
            this.httpClient
                .post<ItemsIds[]>(
                    this.hostnameService.Hostname + '/api/get-items-ids-titles',
                    { code: code, type: type },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    data.sort((e1, e2) => {
                        return e1['id'] - e2['id'];
                    });
                    data.forEach((d) => (d['code'] = code));
                    this.itemIds = data;
                    this.subjectPostUpdated.next(this.getItemsIds());
                    reslove(data);
                });
        });
    }

    async fetchItemIdsMultiTypes(types: string[], code: string = this.accountService.getCode()) {
        let dataMultiTypes: ItemsIds[] = [];

        for (let type of types) {
            let data = await this.fetchItemIds(type, code);
            dataMultiTypes = [...dataMultiTypes, ...data];
        }

        this.itemIds = dataMultiTypes;
        this.subjectPostUpdated.next(this.getItemsIds());

        return dataMultiTypes;
    }

    async fetchItemIdsbyCodesAndTypes(types: string[], codes: string[]) {
        return await new Promise<ItemsIds[]>((reslove, reject) => {
            this.httpClient
                .post<ItemsIds[]>(
                    this.hostnameService.Hostname + '/api/items-ids/get-items-by-codes-and-types',
                    { codes: codes, types: types },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    reslove(data);
                });
        });
    }

    async addItemsIds(title: string, type: string, ids: Array<any>, flag: string, data: any = {}) {
        return await new Promise<string>((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/add-items-ids',
                    {
                        code: this.accountService.getCode(),
                        title: title,
                        ids: ids,
                        type: type,
                        flag: flag,
                        data: data,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async addIdsToItem(id: string, ids: Array<any>, is_keep: boolean) {
        return await new Promise((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/add-ids-to-items',
                    { id: id, ids: ids, is_keep: is_keep },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async removeIdsFromItems(id: string, ids: Array<any>) {
        return await new Promise<string>((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/remove-ids-from-items',
                    { id: id, ids: ids },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async removeLastestIdsFromItems(id: string, ids: Array<any>) {
        return await new Promise((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/items-ids/remove-lastest-ids-from-items',
                    { id: id, ids: ids },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async editIdsInItems(id: string, ids: Array<any>) {
        return await new Promise((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/edit-ids-in-items',
                    { id: id, ids: ids },
                    { withCredentials: true }
                )
                .subscribe(
                    (data) => reslove(data),
                    (error) => reject(error)
                );
        });
    }

    async changeIdsInItems(id: string, ids: Array<any>) {
        return await new Promise((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/change-ids-in-items',
                    { id: id, ids: ids },
                    { withCredentials: true }
                )
                .subscribe(
                    (data) => reslove(data),
                    (error) => reject(error)
                );
        });
    }

    async deleteItems(id: string) {
        return await new Promise((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/delete-items',
                    { id: id },
                    { withCredentials: true }
                )
                .subscribe(
                    (data) => reslove(data),
                    (error) => reject(error)
                );
        });
    }

    async editFlag(id: string, flag: string) {
        return await new Promise((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/edit-flag-items',
                    { id: id, flag: flag },
                    { withCredentials: true }
                )
                .subscribe(
                    (data) => reslove(data),
                    (error) => reject(error)
                );
        });
    }

    async checkExists(title: string, type: string) {
        return await new Promise<any>((reslove, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/check-items-ids',
                    { code: this.accountService.code, title: title, type: type },
                    { withCredentials: true }
                )
                .subscribe(
                    (data) => reslove(data),
                    (error) => reject(error)
                );
        });
    }

    async putItemsIds(
        title: string,
        type: string,
        ids: Array<any>,
        flag: string = '',
        data: any = {},
        is_keep = false
    ) {
        let returnNumber = await this.checkExists(title, type);

        if (returnNumber && returnNumber != 'FAIL') {
            let id: string = returnNumber;
            return await this.addIdsToItem(id, ids, is_keep);
        } else {
            return await this.addItemsIds(title, type, ids, flag, data);
        }
    }
}
