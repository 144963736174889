import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from './account.service';
import { Employee, EmployeeService } from './employee.service';
import { ItemsIds, ItemsIdsServices } from './items_ids.service';

@Injectable({ providedIn: 'root' })
export class InfectedPersonServices {
    ITEMS_IDS_TYPE: string = 'infected-person';

    constructor(
        private employeeService: EmployeeService,
        private itemsIdsService: ItemsIdsServices,
        private accountService: AccountService
    ) {}

    async fetchAvaiablePersion() {
        let employees: Employee[] = await this.employeeService.fetchEmployee();
        let naList: ItemsIds[] = await this.itemsIdsService.fetchItemIds(this.ITEMS_IDS_TYPE);

        let notAvaiableId: Set<string> = new Set<string>();
        naList.forEach((l) => {
            if (
                l.title == 'f0-removed' ||
                l.title == 'f0.5-removed' ||
                l.title == 'f1gan-removed' ||
                l.title == 'f1xa-removed' ||
                l.title == 'f2-removed'
            )
                return;
            l.ids.forEach((id) => notAvaiableId.add(id['id']));
        });

        employees = employees.filter((e) => !notAvaiableId.has(e['id']));

        return employees;
    }

    async addInfectedPersionToList(title: string, ids: Array<any>, flag: string = '', data: any = {}) {
        await this.itemsIdsService.putItemsIds(title, this.ITEMS_IDS_TYPE, ids, flag, data);
    }

    async addNA(ids: Array<any>, flag: string = '', data: any = {}) {
        return await this.addInfectedPersionToList('ca nghi nhiễm (f0.5)', ids, flag, data);
    }

    async addF0(ids: Array<any>, flag: string = '', data: any = {}) {
        return await this.addInfectedPersionToList('f0', ids, flag, data);
    }

    async addF1Gan(ids: Array<any>, flag: string = '', data: any = {}) {
        return await this.addInfectedPersionToList('f1 gần', ids, flag, data);
    }

    async addF1Xa(ids: Array<any>, flag: string = '', data: any = {}) {
        return await this.addInfectedPersionToList('f1 xa', ids, flag, data);
    }

    async addF2(ids: Array<any>, flag: string = '', data: any = {}) {
        return await this.addInfectedPersionToList('f2', ids, flag, data);
    }

    async addF3(ids: Array<any>, flag: string = '', data: any = {}) {
        return await this.addInfectedPersionToList('f3', ids, flag, data);
    }

    async fetchInfectedItemsList(code: string = this.accountService.getCode()) {
        return await this.itemsIdsService.fetchItemIds(this.ITEMS_IDS_TYPE, code);
    }

    async editInfectedItemsList(id: string, ids: Array<any>) {
        return await this.itemsIdsService.editIdsInItems(id, ids);
    }

    async changeInfectedItemsList(id: string, ids: Array<any>) {
        return await this.itemsIdsService.changeIdsInItems(id, ids);
    }

    async fetchInfectedItemsListByCodes(codes: string[]) {
        return await this.itemsIdsService.fetchItemIdsByCodesAndTypes([this.ITEMS_IDS_TYPE], codes);
    }
}
