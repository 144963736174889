import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HostnameService } from './hostname.service';

export interface Location {
    id: string;
    code_px: string;
    name_px: string;
    name_px_en: string;
    level_px: string;
    code_qh: string;
    name_qh: string;
    name_qh_en: string;
    level_qh: string;
    code_tt: string;
    name_tt: string;
    name_tt_en: string;
    level_tt: string;
    fullname: string;
    fullname_en: string;
    boundary: string;
    note: string;
}

@Injectable({ providedIn: 'root' })
export class LocationsService {
    constructor(private httpClient: HttpClient, private hostnameService: HostnameService) {}

    async getMultipleByCodePx(code_pxs: string[]): Promise<Location[]> {
        return await new Promise<Location[]>((resolve, reject) => {
            this.httpClient
                .post<Location[]>(this.hostnameService.MapyHostname + '/api/locations/get-multiple-by-code-px', {
                    code_pxs: code_pxs,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getMultipleByCodeQh(code_qhs: string[]): Promise<Location[]> {
        return await new Promise<Location[]>((resolve, reject) => {
            this.httpClient
                .post<Location[]>(this.hostnameService.MapyHostname + '/api/locations/get-multiple-by-code-qh', {
                    code_qhs: code_qhs,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getMultipleByCodeTt(code_tts: string[]): Promise<Location[]> {
        return await new Promise<Location[]>((resolve, reject) => {
            this.httpClient
                .post<Location[]>(this.hostnameService.MapyHostname + '/api/locations/get-multiple-by-code-tt', {
                    code_tts: code_tts,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
