import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class GeneralDataService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetchAll() {
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .get<any>(this.hostnameService.VnuisAPI + '/api/v1/read/general-data/get-all', {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchAllByType(type: string) {
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .get<any>(this.hostnameService.VnuisAPI + '/api/v1/read/general-data/get-all-by-type?type=' + type, {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
