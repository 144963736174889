import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HostnameService } from './hostname.service';

interface Coordinates {
    province: string;
    district: string;
    ward: string;
    start: Array<number>;
    points: Array<Array<number>>;
}

@Injectable({ providedIn: 'root' })
export class CoordinatesServices {
    constructor(private httpClient: HttpClient, private hostnameService: HostnameService) {}

    async fetchPoints(province: string, district: string, ward: string) {
        return await new Promise<Array<Array<number>>>((reslove, reject) => {
            this.httpClient
                .post<Array<Array<number>>>(
                    this.hostnameService.Hostname + '/api/get-points',
                    { province: province, district: district, ward: ward },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    reslove(data);
                });
        });
    }

    async fetchAllCoordinates() {
        return await new Promise<Coordinates[]>((reslove, reject) => {
            this.httpClient
                .post<Coordinates[]>(this.hostnameService.Hostname + '/api/get-all-coordinates', '', {
                    withCredentials: true,
                })
                .subscribe((data) => {
                    reslove(data);
                });
        });
    }
}
