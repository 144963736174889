import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class FileInternalReceiveService {
    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private accountService: AccountService
    ) {}

    async markAsRead(file_internal_id: any): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getAuthMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/file-internal-receive/mark-as-read',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                        file_internal_id: file_internal_id,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
