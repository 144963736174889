import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HostnameService } from './hostname.service';

export interface SnapshotStandardExmployee {
    id: string;
    code: string;
    tam_tru: string;
    thuong_tru: string;

    18: string;
    19: string;
    20: string;

    22: string;
    23: string;
    24: string;
}

export interface SnapshotRaw {
    ts: string;
    employees: Array<SnapshotStandardExmployee>;
}

export interface SnapshotStandard {
    ts: string;
    employees: Array<SnapshotStandardExmployee>;
}

export interface Snapshot {
    id: string;
    ts: string;
    data_raw: SnapshotRaw;
    data_standard: SnapshotStandard;
}

@Injectable({ providedIn: 'root' })
export class SnapshotService {
    constructor(private httpClient: HttpClient, private hostnameService: HostnameService) {}

    async fetchNewestSnapshotStandard(): Promise<Snapshot> {
        return await new Promise<Snapshot>((resolve, reject) => {
            this.httpClient
                .post<Snapshot>(this.hostnameService.MapyHostname + '/api/snapshot/get-newest-snapshot-standard', {})
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchNewestSnapshotStandardByCode(code: string): Promise<Snapshot> {
        return await new Promise<Snapshot>((resolve, reject) => {
            this.httpClient
                .post<Snapshot>(
                    this.hostnameService.MapyHostname + '/api/snapshot/get-newest-snapshot-standard-by-code',
                    {
                        code: code,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
