import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HostnameService {
    get Hostname() {
        return 'https://qlld.shtp.vn';
    }

    get MapyHostname() {
        return 'https://mapy.vn';
    }

    get VnuisAPI() {
        return 'https://api.vnuhcm.edu.vn';
    }
}
