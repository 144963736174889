import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { HostnameService } from './hostname.service';

export interface AuthencationResponse {
    code: string;
    token: string;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
    isLoginIn: boolean = false;
    code: string = '';
    username: string = '';
    apikey: string = 'user_key';
    attributes: any = {};
    token: string = '';

    constructor(private httpClient: HttpClient, private hostnameService: HostnameService) {}

    public get userValue(): boolean {
        return this.isLoginIn;
    }

    async checkLoginIn(username: string, password: string) {
        return await new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.Hostname + '/api/login', {
                    username: username,
                    password: password,
                })
                .subscribe(
                    (data) => {
                        if (data == 'FAIL') {
                            this.isLoginIn = false;
                            this.code = '';
                            resolve(false);
                        } else {
                            console.log('data: ', data);

                            let response_json: AuthencationResponse = data;
                            console.log('response_json: ', response_json);

                            this.isLoginIn = true;
                            this.code = response_json['code'];
                            this.username = username;

                            resolve(true);
                        }
                    },
                    (error) => {}
                );
        });
    }

    async checkAuth(): Promise<boolean> {
        return await new Promise<boolean>((resolve, reject) => {
            this.httpClient.post<any>(this.hostnameService.Hostname + '/api/check-auth', '').subscribe(
                (data) => {
                    if (data == 'FAIL') {
                        this.isLoginIn = false;
                        this.code = '';
                        resolve(false);
                    } else {
                        console.log('data: ', data);

                        let response_json: AuthencationResponse = data;
                        console.log('response_json: ', response_json);

                        this.isLoginIn = true;
                        this.code = response_json['code'];

                        resolve(true);
                    }
                },
                (error) => {}
            );
        });
    }

    getCode() {
        return this.code;
    }

    async logout() {
        return await new Promise<string>((resolve, reject) => {
            this.httpClient.post<any>(this.hostnameService.Hostname + '/api/logout', '').subscribe(
                (data) => {
                    Swal.fire('Success', 'You have successfully logged out', 'success');
                    window.location.href = '/';
                    resolve(data);
                },
                (error) => {}
            );
        });
    }

    async changePassword(newPassword: string) {
        return await new Promise<string>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.Hostname + '/api/change-password', {
                    username: this.username,
                    new_password: newPassword,
                })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {}
                );
        });
    }

    async getName(code: string = this.getCode()) {
        return await new Promise<string>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.Hostname + '/api/account/get-name-by-code', {
                    code: code,
                })
                .subscribe((data) => {
                    // if (data != 'FAIL') data = data['name'];
                    resolve(data);
                });
        });
    }

    async getNames(codes: string[]): Promise<any[]> {
        return await new Promise<any[]>((resolve, reject) => {
            this.httpClient
                .post<any[]>(this.hostnameService.Hostname + '/api/account/get-names-by-codes', {
                    codes: codes,
                })
                .subscribe((data) => {
                    // if (data != 'FAIL') data = data['name'];
                    resolve(data);
                });
        });
    }

    async fetchTotalCompany(): Promise<number> {
        return await new Promise<number>((resolve, reject) => {
            this.httpClient
                .post<number>(this.hostnameService.Hostname + '/api/account/get-total-company', '')
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    getAuthMetadata() {
        let final_metadata: { ma_don_vi: string; mat_khau_don_vi: string } = {
            ma_don_vi: '',
            mat_khau_don_vi: '',
        };
        if (this.attributes.ma_don_vi) final_metadata.ma_don_vi = this.attributes.ma_don_vi[0];
        if (this.attributes.mat_khau_don_vi) final_metadata.mat_khau_don_vi = this.attributes.mat_khau_don_vi[0];
        return final_metadata;
    }
}
