import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import Swal from "sweetalert2";
import { AccountService } from "../account.service";
import { HostnameService } from "../hostname.service";

export const PHAN_CAP = ["1", "2", "3"];
export const LOAI = [
    "SỐ LƯỢNG CÁN BỘ CHỦ TRÌ ĐỀ TÀI, DỰ ÁN KHOA HỌC VÀ CÔNG NGHỆ CẤP NHÀ NƯỚC VÀ CẤP ĐẠI HỌC QUỐC GIA",
];

@Injectable({ providedIn: "root" })
export class B20Service {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetchInfo(nam: string, loai_bao_cao: string, ma_don_vi_bao_cao: string, ma_don_vi: string = "") {
        return await new Promise<any>((resolve, reject) => {
            let metadata: any = {};
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;
            metadata.ma_don_vi_bao_cao = ma_don_vi_bao_cao;

            if (!ma_don_vi || ma_don_vi == "") ma_don_vi = metadata.ma_don_vi;

            metadata.loai_bao_cao = loai_bao_cao;
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/read/annual-report/b20/get",
                    {
                        metadata: metadata,
                        nam: nam,
                        ma_don_vi: ma_don_vi,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchAllInfo(nam: string, loai_bao_cao: string) {
        return await new Promise<any>((resolve, reject) => {
            let metadata: any = {};
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;
            metadata.loai_bao_cao = loai_bao_cao;
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/read/annual-report/b20/get-all",
                    {
                        metadata: metadata,
                        nam: nam,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async addB20(metadata: any, data: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;

            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/staging/annual-report/b20/add",
                    {
                        metadata: metadata,
                        data: data,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async addB20BeforeAfter(metadata: any, data: any, position: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/staging/annual-report/b20/add-before-after",
                    {
                        metadata: metadata,
                        data: data,
                        position: position,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async editB20(metadata: any, data: any, position: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;

            this.httpClient
                .put<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/staging/annual-report/b20/edit",
                    {
                        metadata: metadata,
                        data: data,
                        position: position,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async deleteB20(metadata: any, position: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;

            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/staging/annual-report/b20/delete",
                    {
                        metadata: metadata,
                        position: position,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
