import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HostnameService } from './hostname.service';

export interface Boundaries {
    type: string;
    name: string;
    start: Array<any>;
    points: Array<any>;
}

@Injectable({ providedIn: 'root' })
export class BoundariesService {
    constructor(private httpClient: HttpClient, private hostnameService: HostnameService) {}

    async getAll() {
        return await new Promise<Boundaries[]>((resolve, reject) => {
            this.httpClient
                .post<Boundaries[]>(this.hostnameService.MapyHostname + '/api/boundaries/get-all', {})
                .subscribe((data) => {
                    data = data.sort((d1, d2) => {
                        if (d1.name > d2.name) return 1;
                        if (d1.name < d2.name) return -1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async getByType(type: string) {
        return await new Promise<Boundaries[]>((resolve, reject) => {
            this.httpClient
                .post<Boundaries[]>(this.hostnameService.MapyHostname + '/api/boundaries/get-by-type', {
                    type: type,
                })
                .subscribe((data) => {
                    data = data.sort((d1, d2) => {
                        if (d1.name > d2.name) return 1;
                        if (d1.name < d2.name) return -1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async getByName(type: string, name: string) {
        return await new Promise<Boundaries[]>((resolve, reject) => {
            this.httpClient
                .post<Boundaries[]>(this.hostnameService.MapyHostname + '/api/boundaries/get-by-name', {
                    type: type,
                    name: name,
                })
                .subscribe((data) => {
                    data = data.sort((d1, d2) => {
                        if (d1.name > d2.name) return 1;
                        if (d1.name < d2.name) return -1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    // API SHTP

    async getSnapshotStandard(codeCompany: string) {
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.MapyHostname + '/api/snapshot/get-snapshot-standard-by-code', {
                    codeCompany: codeCompany,
                })
                .subscribe((data) => {
                    // data = data.boundary;
                    console.log(data);
                    resolve(data);
                });
        });
    }

    async getBoundary(codepx: string) {
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.MapyHostname + '/api/locations/get-multiple-by-code-px', {
                    codepx: codepx,
                })
                .subscribe((data) => {
                    console.log(data);
                    resolve(data);
                });
        });
    }
}
