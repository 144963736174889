import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

export const TRANG_THAI = ['Dự thảo', 'Chờ xử lý', 'Đã ban hành', 'Đã xử lý'];

@Injectable({ providedIn: 'root' })
export class FileInternalService {
    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private accountService: AccountService
    ) {}

    async getCurrentDraft(): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getAuthMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/file-internal/get-current-draft',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getAllSenderHeader(): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getAuthMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/file-internal/get-all-sender-header',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getAllReceiverHeader(): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getAuthMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/file-internal/get-all-receiver-header',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getMessageById(id: string): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getAuthMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/file-internal/get-by-id',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                        file_internal_id: id,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async editDraft(id: string, data: any): Promise<any> {
        return await new Promise<any>((resolve, reject) => {
            let authMetadata = this.accountService.getAuthMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/file-internal/edit-draft',
                    {
                        metadata: {
                            ma_don_vi: authMetadata.ma_don_vi,
                            mat_khau_don_vi: authMetadata.mat_khau_don_vi,
                        },
                        file_internal_id: id,
                        data: data
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
