import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HostnameService } from './hostname.service';

export interface BHTY {
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
    9: string;
    10: string;
    11: string;
    12: string;
    13: string;
    14: string;
    15: string;
    16: string;
    17: string;
    18: string;
    19: string;
}

@Injectable({ providedIn: 'root' })
export class BHTYService {
    constructor(private httpClient: HttpClient, private hostnameService: HostnameService) {}

    async getAll() {
        return await new Promise<BHTY[]>((resolve, reject) => {
            this.httpClient
                .post<BHTY[]>(this.hostnameService.MapyHostname + '/api/bhty/get-all', {})
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getByDistrict(district: string) {
        return await new Promise<BHTY[]>((resolve, reject) => {
            this.httpClient
                .post<BHTY[]>(this.hostnameService.MapyHostname + '/api/bhty/get-by-district', {
                    district: district,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getByWard(district: string, ward: string) {
        return await new Promise<BHTY[]>((resolve, reject) => {
            this.httpClient
                .post<BHTY[]>(this.hostnameService.MapyHostname + '/api/bhty/get-by-ward', {
                    district: district,
                    ward: ward,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getDistrict() {
        return await new Promise<string[]>((resolve, reject) => {
            this.httpClient
                .post<string[]>(this.hostnameService.MapyHostname + '/api/bhty/get-district', {})
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getWard(district: string) {
        return await new Promise<string[]>((resolve, reject) => {
            this.httpClient
                .post<string[]>(this.hostnameService.MapyHostname + '/api/bhty/get-ward', {
                    district: district,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
