import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { HostnameService } from './hostname.service';

export interface CompaniesInfoBoundary {
    id: string;
    so_lo_dat: string;
    ten_dn: string;
    ma_so_thue: string;
    ten_dn_cad: string;
    khu_chuc_nang: string;
    boundary: string;
}

@Injectable({ providedIn: 'root' })
export class CompaniesService {
    constructor(private httpClient: HttpClient, private hostnameService: HostnameService) {}

    async getAll(): Promise<CompaniesInfoBoundary[]> {
        return await new Promise<CompaniesInfoBoundary[]>((resolve, reject) => {
            this.httpClient
                .post<CompaniesInfoBoundary[]>(this.hostnameService.MapyHostname + '/api/companies/get-all', {})
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
