import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class ChartDataService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getInplaceChart2(codes: string[]) {
        return await new Promise<any>((reslove, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.Hostname + '/api/items-ids/get-implace-chart-2',
                    {
                        codes: codes,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async getChartProvince(codes: string[]) {
        return await new Promise<any>((reslove, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.MapyHostname + '/api/snapshot/get-chart-province',
                    {
                        codes: codes,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async getEmployeeGenderChart(codes: string[]) {
        return await new Promise<any>((reslove, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.Hostname + '/api/employee/get-empoyee-gender-chart',
                    {
                        codes: codes,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async getChartFxByTime(codes: string[]) {
        return await new Promise<any>((reslove, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.Hostname + '/api/items-ids/get-chart-fx-by-time',
                    {
                        codes: codes,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async getChartF0BySpace(codes: string[]) {
        return await new Promise<any>((reslove, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.Hostname + '/api/items-ids/get-chart-f0-by-space',
                    {
                        codes: codes,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async getNumberEmployeeInplace(codes: string[]) {
        return await new Promise<any>((reslove, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.Hostname + '/api/items-ids/get-number-employee-inplace',
                    {
                        codes: codes,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }

    async getNumberEmployeeVaccination(codes: string[]) {
        return await new Promise<any>((reslove, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.Hostname + '/api/items-ids/get-number-employee-vaccination',
                    {
                        codes: codes,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => reslove(data));
        });
    }
}
