import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component';
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import { HorizontalAppHeaderComponent } from './layouts/full/horizontal-header/horizontal-header.component';
import { HorizontalAppSidebarComponent } from './layouts/full/horizontal-sidebar/horizontal-sidebar.component';

import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { mailService, mailGlobalVariable } from './apps/mailbox/mail.service';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './providers/language.service';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { EmployeeService } from './providers/employee.service';
import { AccountService } from './providers/account.service';

import { ItemsIdsServices } from './providers/items_ids.service';
import { InfectedPersonServices } from './providers/infected_persion.service';
import { CoordinatesServices } from './providers/coordinates.services';
import { EmployeeLonLatServices } from './providers/employee_lon_lat.service';
import { SelectedEmployeeListService } from './providers/selected-employee-list.service';
import { InfectedReportServices } from './providers/infected_report.services';
import { UserModule } from './user/user.module';
import { InspectorCodeMappingService } from './providers/inspector_code_mapping.services';
import { HostnameService } from './providers/hostname.service';
import { ChartDataService } from './providers/chart.service';
import { BHTYService } from './providers/bhty.service';
import { LocationsService } from './providers/locations.service';
import { BoundariesService } from './providers/boundaries.service';
import { CompaniesService } from './providers/conpanies.service';
import { SnapshotService } from './providers/snapshot.service';
import { LearnerService } from './providers/learner.service';
import { StaffService } from './providers/staff.service';
import { StudentService } from './providers/student.service';
import { initializeKeycloak } from './helper/auth.init';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { OrgService } from './providers/org.service';
import { UtilitieAddressService } from './providers/utilities-address.service';
import { FileService } from './providers/file.service';
import { FilesDownloadedService } from './providers/files-downloaded.service';

import { Pl1Service } from './providers/report-office/pl_1.service';
import { Pl2Service } from './providers/report-office/pl_2.service';
import { Pl3Service } from './providers/report-office/pl_3.service';
import { Pl4Service } from './providers/report-office/pl_4.service';
import { Pl5Service } from './providers/report-office/pl_5.service';
import { Pl6Service } from './providers/report-office/pl_6.service';
import { Pl7Service } from './providers/report-office/pl_7.service';
import { Pl8Service } from './providers/report-office/pl_8.service';
import { Pl9Service } from './providers/report-office/pl_9.service';
import { Pl10Service } from './providers/report-office/pl_10.service';
import { Pl11Service } from './providers/report-office/pl_11.service';
import { Pl12Service } from './providers/report-office/pl_12.service';
import { Pl13Service } from './providers/report-office/pl_13.service';
import { Pl14Service } from './providers/report-office/pl_14.service';
import { Pl15Service } from './providers/report-office/pl_15.service';
import { Pl16Service } from './providers/report-office/pl_16.service';
import { Pl17Service } from './providers/report-office/pl_17.service';
import { Pl18Service } from './providers/report-office/pl_18.service';
import { ReportOfficeService } from './providers/report-office/report-office.service';
import { OrganizationService } from './providers/organization.service';
import { PLFilesService } from './providers/report-office/files.service ';
import { M1Service } from './providers/report-staff-organization/m1.service';
import { ReportStaffOrganizationService } from './providers/report-staff-organization/report-staff-organization.service';
import { MFilesService } from './providers/report-staff-organization/mfiles.service';
import { M2Service } from './providers/report-staff-organization/m2.service';
import { M3Service } from './providers/report-staff-organization/m3.service';
import { M4Service } from './providers/report-staff-organization/m4.service';
import { AnnualReportService } from './providers/annual-report/annual-report.service';
import { AnnualFilesService } from './providers/annual-report/annual-files.service';
import { B1Service } from './providers/annual-report/b1.service';
import { B2Service } from './providers/annual-report/b2.service';
import { B3Service } from './providers/annual-report/b3.service';
import { B4Service } from './providers/annual-report/b4.service';
import { B5Service } from './providers/annual-report/b5.service';
import { B6Service } from './providers/annual-report/b6.service';
import { B7Service } from './providers/annual-report/b7.service';
import { B8Service } from './providers/annual-report/b8.service';
import { B9Service } from './providers/annual-report/b9.service';
import { B10Service } from './providers/annual-report/b10.service';
import { B11Service } from './providers/annual-report/b11.service';
import { B12Service } from './providers/annual-report/b12.service';
import { B13Service } from './providers/annual-report/b13.service';
import { B14Service } from './providers/annual-report/b14.service';
import { B15AService } from './providers/annual-report/b15a.service';
import { B15BService } from './providers/annual-report/b15b.service';
import { B16AService } from './providers/annual-report/b16a.service';
import { B16BService } from './providers/annual-report/b16b.service';
import { B17AService } from './providers/annual-report/b17a.service';
import { B17BService } from './providers/annual-report/b17b.service';
import { B18Service } from './providers/annual-report/b18.service';
import { B19Service } from './providers/annual-report/b19.service';
import { B20Service } from './providers/annual-report/b20.service';
import { B21Service } from './providers/annual-report/b21.service';
import { B22Service } from './providers/annual-report/b22.service';
import { B23Service } from './providers/annual-report/b23.service';
import { B24AService } from './providers/annual-report/b24a.service';
import { B24BService } from './providers/annual-report/b24b.service';
import { B24CService } from './providers/annual-report/b24c.service';
import { B24DService } from './providers/annual-report/b24d.service';
import { B25Service } from './providers/annual-report/b25.service';
import { B26AService } from './providers/annual-report/b26a.service';
import { B26BService } from './providers/annual-report/b26b.service';
import { B27AService } from './providers/annual-report/b27a.service';
import { B27BService } from './providers/annual-report/b27b.service';
import { B28Service } from './providers/annual-report/b28.service';
import { B29AService } from './providers/annual-report/b29a.service';
import { B29BService } from './providers/annual-report/b29b.service';
import { B30Service } from './providers/annual-report/b30.service';
import { FileInternalService } from './providers/file-internal/file-internal.service';
import { FileInternalReceiveService } from './providers/file-internal/file-internal-receive.service';
import { FileInternalSendService } from './providers/file-internal/file-internal-send.service';
import { GeneralDataService } from './providers/general-data.service';
import { VanPhongCodesService } from './providers/van_phong_codes.service';

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true,
};

@NgModule({
    declarations: [
        AppComponent,
        FullComponent,
        VerticalAppHeaderComponent,
        SpinnerComponent,
        AppBlankComponent,
        VerticalAppSidebarComponent,
        AppBreadcrumbComponent,
        HorizontalAppHeaderComponent,
        HorizontalAppSidebarComponent,
    ],
    imports: [
        KeycloakAngularModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        BrowserModule,
        BrowserAnimationsModule,
        DemoMaterialModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        PerfectScrollbarModule,
        SharedModule,
        NgMultiSelectDropDownModule.forRoot(),
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        // UserModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        mailService,
        mailGlobalVariable,
        DatePipe,
        LanguageService,
        EmployeeService,
        AccountService,
        ItemsIdsServices,
        InfectedPersonServices,
        CoordinatesServices,
        EmployeeLonLatServices,
        SelectedEmployeeListService,
        InfectedReportServices,
        InspectorCodeMappingService,
        HostnameService,
        ChartDataService,

        BHTYService,
        LocationsService,
        BoundariesService,
        CompaniesService,
        SnapshotService,

        LearnerService,
        StaffService,
        StudentService,
        OrgService,
        UtilitieAddressService,
        FileService,
        FilesDownloadedService,

        PLFilesService,
        Pl1Service,
        Pl2Service,
        Pl3Service,
        Pl4Service,
        Pl5Service,
        Pl6Service,
        Pl7Service,
        Pl8Service,
        Pl9Service,
        Pl10Service,
        Pl11Service,
        Pl12Service,
        Pl13Service,
        Pl14Service,
        Pl15Service,
        Pl16Service,
        Pl17Service,
        Pl18Service,
        ReportOfficeService,
        OrganizationService,

        MFilesService,
        ReportStaffOrganizationService,
        M1Service,
        M2Service,
        M3Service,
        M4Service,

        AnnualReportService,
        AnnualFilesService,
        B1Service,
        B2Service,
        B3Service,
        B4Service,
        B5Service,
        B6Service,
        B7Service,
        B8Service,
        B9Service,
        B10Service,
        B11Service,
        B12Service,
        B13Service,
        B14Service,
        B15AService,
        B15BService,
        B16AService,
        B16BService,
        B17AService,
        B17BService,
        B18Service,
        B19Service,
        B20Service,
        B21Service,
        B22Service,
        B23Service,
        B24AService,
        B24BService,
        B24CService,
        B24DService,
        B25Service,
        B26AService,
        B26BService,
        B27AService,
        B27BService,
        B28Service,
        B29AService,
        B29BService,
        B30Service,

        FileInternalService,
        FileInternalSendService,
        FileInternalReceiveService,
        GeneralDataService,
        VanPhongCodesService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
