import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import Swal from "sweetalert2";
import { AccountService } from "../account.service";
import { HostnameService } from "../hostname.service";

@Injectable({ providedIn: "root" })
export class AnnualFilesService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetch(nam: string, loai_bao_cao: string, ma_don_vi: string = "") {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            if (!ma_don_vi || ma_don_vi == "") ma_don_vi = final_metadata.ma_don_vi;
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/read/annual-report/files/get",
                    {
                        metadata: {
                            ma_don_vi: final_metadata.ma_don_vi,
                            mat_khau_don_vi: final_metadata.mat_khau_don_vi,
                            loai_bao_cao: loai_bao_cao,
                        },
                        nam: nam,
                        ma_don_vi: ma_don_vi,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchAllByNam(nam: string, loai_bao_cao: string) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/read/annual-report/files/get-all-by-nam",
                    {
                        metadata: {
                            ma_don_vi: final_metadata.ma_don_vi,
                            mat_khau_don_vi: final_metadata.mat_khau_don_vi,
                            loai_bao_cao: loai_bao_cao,
                        },
                        nam: nam,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async add(metadata: any, file: any, display_name: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;

            let formData = new FormData();
            formData.append("file", file);
            formData.append("metadata", JSON.stringify(metadata));
            formData.append("display_name", display_name);

            this.httpClient
                .post<any>(this.hostnameService.VnuisAPI + "/api/v1/staging/annual-report/files/add", formData, {
                    headers: { apikey: this.accountService.apikey },
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async delete(nam: string, loai_bao_cao: string, filename: string) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            let metadata = {
                ma_don_vi: final_metadata.ma_don_vi,
                mat_khau_don_vi: final_metadata.mat_khau_don_vi,
                nam: nam,
                loai_bao_cao: loai_bao_cao,
            };

            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + "/api/v1/staging/annual-report/files/delete",
                    {
                        metadata: metadata,
                        filename: filename,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
