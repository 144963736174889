import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Employee } from './employee.service';

@Injectable({ providedIn: 'root' })
export class SelectedEmployeeListService {
    selectedEmployee: Employee[] = [];

    private subjectPostUpdated: Subject<Employee[]> = new Subject<Employee[]>();

    getEmployeeUpdateListener() {
        return this.subjectPostUpdated.asObservable();
    }

    getSelectedEmployee() {
        return this.selectedEmployee;
    }

    setSelectedEmployee(newEmployee: Employee[]) {
        this.selectedEmployee = newEmployee;
        this.subjectPostUpdated.next(newEmployee);
    }
}
