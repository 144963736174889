import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

export interface InfectedReport {
    id: string;
    code: string;
    dau_thoi_gian: string;
    tong_ld: string;
    so_duong_khu: string;
    phuong: string;
    khu_vuc: string;
    li_do_xn: string;
    loai_xn: string;
    nguoi_bc: string;
    so_dien_thoai: string;
    sl_ld_xet_nghiem_nhanh: string;
    slld_test_nhanh_duong_tinh: string;
    slld_xn_rt_ptr: string;
    sl_f0: string;
    sl_ca_nghi_nhiem: string;
    sl_f1_gan: string;
    sl_f1_xa: string;
    sl_f2: string;
    sl_f0_chua_chuyen: string;
    sl_f1_chua_chuyen: string;
    ghi_chu: string;
}

@Injectable({ providedIn: 'root' })
export class InfectedReportServices {
    private infectedReports: InfectedReport[] = [];
    private subjectPostUpdated: Subject<InfectedReport[]> = new Subject<InfectedReport[]>();

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    getData() {
        return this.infectedReports;
    }

    getUpdateListener() {
        return this.subjectPostUpdated.asObservable();
    }

    async fetchAll() {
        return await new Promise<InfectedReport[]>((resolve, reject) => {
            this.httpClient
                .post<InfectedReport[]>(this.hostnameService.Hostname + '/api/infected-report/get-all', '', {
                    withCredentials: true,
                })
                .subscribe((data) => {
                    data = data.sort((i1, i2) => {
                        if (i1['dau_thoi_gian'] > i2['dau_thoi_gian']) return 1;
                        if (i1['dau_thoi_gian'] < i2['dau_thoi_gian']) return 1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async fetch(code: string = this.accountService.getCode()) {
        return await new Promise<InfectedReport[]>((resolve, reject) => {
            this.httpClient
                .post<InfectedReport[]>(
                    this.hostnameService.Hostname + '/api/infected-report/get',
                    { code: code },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    data = data.sort((i1, i2) => {
                        if (i1['dau_thoi_gian'] > i2['dau_thoi_gian']) return 1;
                        if (i1['dau_thoi_gian'] < i2['dau_thoi_gian']) return 1;
                        return 0;
                    });
                    this.infectedReports = data;
                    this.subjectPostUpdated.next(data);
                    resolve(data);
                });
        });
    }

    async fetchByCodes(codes: string[]) {
        // let data: InfectedReport[] = [];

        // for (let code of codes) {
        //     let newData = await this.fetch(code);
        //     data = [...data, ...newData];
        // }

        // data = data.sort((i1, i2) => {
        //     if (i1['dau_thoi_gian'] > i2['dau_thoi_gian']) return 1;
        //     if (i1['dau_thoi_gian'] < i2['dau_thoi_gian']) return 1;
        //     return 0;
        // });

        // return data;

        return await new Promise<InfectedReport[]>((resolve, reject) => {
            this.httpClient
                .post<InfectedReport[]>(
                    this.hostnameService.Hostname + '/api/infected-report/get-by-codes',
                    { codes: codes },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    data = data.sort((i1, i2) => {
                        if (i1['dau_thoi_gian'] > i2['dau_thoi_gian']) return 1;
                        if (i1['dau_thoi_gian'] < i2['dau_thoi_gian']) return 1;
                        return 0;
                    });
                    this.infectedReports = data;
                    this.subjectPostUpdated.next(data);
                    resolve(data);
                });
        });
    }

    async fetchLastestBasic() {
        return await new Promise<InfectedReport>((resolve, reject) => {
            this.httpClient
                .post<InfectedReport>(
                    this.hostnameService.Hostname + '/api/infected-report/get-lastest-basic',
                    { code: this.accountService.getCode() },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async add(infectedReport: InfectedReport) {
        return await new Promise<string>((resolve, reject) => {
            this.httpClient
                .post<string>(
                    this.hostnameService.Hostname + '/api/infected-report/add',
                    {
                        code: this.accountService.getCode(),
                        tong_ld: infectedReport['tong_ld'],
                        so_duong_khu: infectedReport['so_duong_khu'],
                        phuong: infectedReport['phuong'],
                        khu_vuc: infectedReport['khu_vuc'],
                        li_do_xn: infectedReport['li_do_xn'],
                        loai_xn: infectedReport['loai_xn'],
                        nguoi_bc: infectedReport['nguoi_bc'],
                        so_dien_thoai: infectedReport['so_dien_thoai'],
                        sl_ld_xet_nghiem_nhanh: infectedReport['sl_ld_xet_nghiem_nhanh'],
                        slld_test_nhanh_duong_tinh: infectedReport['slld_test_nhanh_duong_tinh'],
                        slld_xn_rt_ptr: infectedReport['slld_xn_rt_ptr'],
                        sl_f0: infectedReport['sl_f0'],
                        sl_ca_nghi_nhiem: infectedReport['sl_ca_nghi_nhiem'],
                        sl_f1_gan: infectedReport['sl_f1_gan'],
                        sl_f1_xa: infectedReport['sl_f1_xa'],
                        sl_f2: infectedReport['sl_f2'],
                        sl_f0_chua_chuyen: infectedReport['sl_f0_chua_chuyen'],
                        sl_f1_chua_chuyen: infectedReport['sl_f1_chua_chuyen'],
                        ghi_chu: infectedReport['ghi_chu'],
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async edit(infectedReport: InfectedReport) {
        return await new Promise<InfectedReport[]>((resolve, reject) => {
            this.httpClient
                .post<InfectedReport[]>(
                    this.hostnameService.Hostname + '/api/infected-report/edit',
                    {
                        code: this.accountService.getCode(),
                        tong_ld: infectedReport['tong_ld'],
                        so_duong_khu: infectedReport['so_duong_khu'],
                        phuong: infectedReport['phuong'],
                        khu_vuc: infectedReport['khu_vuc'],
                        li_do_xn: infectedReport['li_do_xn'],
                        loai_xn: infectedReport['loai_xn'],
                        nguoi_bc: infectedReport['nguoi_bc'],
                        so_dien_thoai: infectedReport['so_dien_thoai'],
                        sl_ld_xet_nghiem_nhanh: infectedReport['sl_ld_xet_nghiem_nhanh'],
                        slld_test_nhanh_duong_tinh: infectedReport['slld_test_nhanh_duong_tinh'],
                        slld_xn_rt_ptr: infectedReport['slld_xn_rt_ptr'],
                        sl_f0: infectedReport['sl_f0'],
                        sl_ca_nghi_nhiem: infectedReport['sl_ca_nghi_nhiem'],
                        sl_f1_gan: infectedReport['sl_f1_gan'],
                        sl_f1_xa: infectedReport['sl_f1_xa'],
                        sl_f2: infectedReport['sl_f2'],
                        sl_f0_chua_chuyen: infectedReport['sl_f0_chua_chuyen'],
                        sl_f1_chua_chuyen: infectedReport['sl_f1_chua_chuyen'],
                        ghi_chu: infectedReport['ghi_chu'],
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async delete(id: string) {
        return await new Promise<InfectedReport[]>((resolve, reject) => {
            this.httpClient
                .post<InfectedReport[]>(
                    this.hostnameService.Hostname + '/api/infected-report/delete',
                    { id: id },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
