import { Injectable } from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

const MENUITEMS = [
    {
        state: 'dashboards',
        name: 'Dashboards',
        type: 'link',
        icon: 'av_timer',
    },
    {
        state: 'employee',
        name: 'Employee',
        type: 'sub',
        icon: 'apps',
        children: [
            { state: 'employeelist', name: 'List of Employees', type: 'link' },
            { state: 'addemployee', name: 'Add Employees', type: 'link' },
            { state: 'editemployee', name: 'Edit Employees', type: 'link' },
        ],
    },
    {
        state: 'test-list',
        name: 'Test List',
        type: 'sub',
        icon: 'fingerprint',
        children: [
            { state: 'employeelist', name: 'List of Tests', type: 'link' },
            { state: 'addlist', name: 'Add Test List', type: 'link' },
            // { state: "phase1", name: "List of Phase 1", type: "link" },
            // { state: "phase2", name: "List of Phase 2", type: "link" },
        ],
    },
    {
        state: 'vaccination',
        name: 'Vaccination',
        type: 'sub',
        icon: 'colorize',
        children: [
            { state: 'vaccinationlist', name: 'List of Vaccination', type: 'link' },
            { state: 'addvaccination', name: 'Add Vaccination', type: 'link' },
        ],
    },
    {
        state: 'labor-contract',
        name: 'Labor Contract',
        type: 'sub',
        icon: 'perm_contact_calendar',
        children: [
            {
                state: 'laborcontractlist',
                name: 'List of Labor Contract',
                type: 'link',
            },
            {
                state: 'addlaborcontract',
                name: 'Add Labor Contract',
                type: 'link',
            },
        ],
    },
    {
        state: 'infected-person',
        name: 'Infected Person',
        type: 'sub',
        icon: 'hotel',
        children: [
            {
                state: 'infectedpersonlist',
                name: 'List of Infected Person',
                type: 'link',
            },
            { state: 'addinfectedperson', name: 'Add Infected Person', type: 'link' },
            { state: 'infectedreport', name: 'Infected Report', type: 'link' },
        ],
    },
    // {
    //     state: "utilities",
    //     name: "Utilities",
    //     type: "sub",
    //     icon: "extension",
    //     children: [
    //         {
    //             state: "editemployee",
    //             name: "Edit Employee",
    //             type: "link",
    //         },
    //     ],
    // },
    {
        state: 'accounts',
        name: 'Accounts',
        type: 'sub',
        icon: 'account_circle',
        children: [{ state: 'editaccounts', name: 'Edit Account', type: 'link' }],
    },

    /******************************************************************************************* */
    // {
    //     state: "location",
    //     name: "Location",
    //     type: "sub",
    //     icon: "web",
    //     children: [
    //         { state: "dynamic-table", name: "Dynamic Table", type: "link" },
    //         { state: "province", name: "Province", type: "link" },
    //         { state: "district", name: "District", type: "link" },
    //         { state: "ward", name: "Ward", type: "link" },
    //     ],
    // // },
    // {
    //     state: "employee",
    //     name: "Employee",
    //     type: "sub",
    //     icon: "web",
    //     children: [{ state: "list", name: "List", type: "link" }],
    // },
];

@Injectable()
export class MenuItems {
    getMenuitem(): Menu[] {
        return MENUITEMS;
    }
}
