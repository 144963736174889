import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

export interface Staff {
    ma_cb: string;
    email: string;
    phone: string;
    ho_ten_dem: string;
    ten: string;
    ngay_sinh: string;
    gioi_tinh: string;
    quoc_tich: string;
    dan_toc: string;
    ton_giao: string;
    dia_chi_lien_he: string;
    ma_so_thue: string;
    so_so_bhxh: string;
    so_the_bhyt: string;
    ngay_vao_doan: string;
    ngay_vao_dang_du_bi: string;
    ngay_vao_dang_chinh_thuc: string;
    tinh_trang_hien_tai: string;

    noi_sinh: any;
    cmnd_cccd: any;
    que_quan: any;
    dia_chi_thuong_tru: any;
    chuc_danh_nghe_nghiep: any;
    chuc_vu_trong_dang: any;
    // hop_dong_lao_dong: any;
    qua_trinh_keo_dai_thoi_gian_cong_tac: any;

    qua_trinh_dao_tao_hoc_vi: any[];
    hoc_ham: any[];
    danh_hieu_nha_giao: any[];
    qua_trinh_cong_tac: any[];
    trinh_do_ly_luan_chinh_tri: any[];
    trinh_do_ngoai_ngu: any[];
    trinh_do_tin_hoc: any[];
    hop_dong_lao_dong: any[];
    qua_trinh_boi_duong: any[];
    khen_thuong: any[];
    ky_luat: any[];
    tai_khoan_ngan_hang: any[];
    quan_he_gia_dinh: any[];
    chuyen_mon: any[];
    cong_tac_nuoc_ngoai: any[];
    giai_thuong_ca_nhan: any[];
    qua_trinh_nghien_cuu: any[];
    de_tai_du_an_nghien_cuu: any[];
    xuat_ban_khoa_hoc: any[];
    bien_tap_khoa_hoc: any[];
    sang_che: any[];
    giai_phap_huu_ich: any[];
    chuyen_giao_cong_nghe: any[];
    huong_dan_luan_van: any[];
    bien_soan_tai_lieu_giang_day: any[];
    chuong_trinh_hop_tac: any[];
    hoat_dong_ben_ngoai: any[];
    tham_gia_su_kien: any[];
}

@Injectable({ providedIn: 'root' })
export class StaffService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    // async fetchStaff() {
    //     return await new Promise<Staff[]>((resolve, reject) => {
    //         this.httpClient
    //             .post<Staff[]>(this.hostnameService.VnuisAPI + '/api/v1/read/staff/get-all', {
    //                 apikey: this.accountService.apikey,
    //             })
    //             .subscribe((data) => {
    //                 data.sort((e1: any, e2: any) => {
    //                     if (e1.data.ma_cb < e2.data.ma_cb) return -1;
    //                     if (e1.data.ma_cb > e2.data.ma_cb) return 1;
    //                     return 0;
    //                 });
    //                 resolve(data);
    //             });
    //     });
    // }

    async fetchStaffByMetadata() {
        return await new Promise<Staff[]>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();

            this.httpClient
                .post<Staff[]>(this.hostnameService.VnuisAPI + '/api/v1/read/staff/get-all-by-metadata', {
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                })
                .subscribe((data) => {
                    data.sort((e1: any, e2: any) => {
                        if (e1.data.ma_cb < e2.data.ma_cb) return -1;
                        if (e1.data.ma_cb > e2.data.ma_cb) return 1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async fetchStaffPartByMetadata() {
        return await new Promise<Staff[]>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();

            this.httpClient
                .post<Staff[]>(this.hostnameService.VnuisAPI + '/api/v1/read/staff/get-part-by-metadata', {
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                })
                .subscribe((data) => {
                    data.sort((e1: any, e2: any) => {
                        if (e1.data.ma_cb < e2.data.ma_cb) return -1;
                        if (e1.data.ma_cb > e2.data.ma_cb) return 1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async deleteById(id: string) {
        let final_metadata: any = this.accountService.getAuthMetadata();
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.VnuisAPI + '/api/v1/staging/staff/delete', {
                    id: id,
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async extractStaffList() {
        let url =
            this.hostnameService.VnuisAPI + '/api/v1/read/staff/extract-list.xlsx?apikey=' + this.accountService.apikey;
        let final_metadata: any = this.accountService.getAuthMetadata();
        url += '&metadata=' + JSON.stringify(final_metadata);

        // var a = document.createElement('a');
        // a.href = url;
        // a.setAttribute('download', 'output.xlsx');
        // a.click();

        this.httpClient.get(url).subscribe((data) => {});
    }

    downloadTemplate() {
        let url =
            this.hostnameService.VnuisAPI +
            '/api/v1/read/staff/download-template.xlsx?apikey=' +
            this.accountService.apikey;
        var a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', 'template.xlsx');
        a.click();
    }

    async addMultiple(staffs: Staff[], metadata: any) {
        let final_metadata: any = this.accountService.getAuthMetadata();
        final_metadata = { ...metadata, ...final_metadata };
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI +
                        '/api/v1/staging/staff/add-multiple?apikey=' +
                        this.accountService.apikey,
                    {
                        apikey: this.accountService.apikey,
                        metadata: final_metadata,
                        data: staffs,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async count() {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/staff/count',
                    {
                        metadata: final_metadata,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
