import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

export interface InspectorCompany {
    code: string;
    name: string;
}

@Injectable({ providedIn: 'root' })
export class InspectorCodeMappingService {
    private currentInspectCode: string = '';

    // Để tạo và bắt sự kiện khi có cập nhật dữ liệu
    private subjectCurrentInspectCodeUpdated: Subject<string> = new Subject<string>();

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetchCodes() {
        return await new Promise<string[]>((resolve, reject) => {
            this.httpClient
                .post<string[]>(this.hostnameService.Hostname + '/api/inspector-code-mapping/get-codes', {
                    code: this.accountService.getCode(),
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchCompany() {
        return await new Promise<InspectorCompany[]>((resolve, reject) => {
            this.httpClient
                .post<InspectorCompany[]>(this.hostnameService.Hostname + '/api/inspector-code-mapping/get-company', {
                    code: this.accountService.getCode(),
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    getCurrentInspectCode() {
        return this.currentInspectCode;
    }

    setCurrentInspectCode(code: string) {
        this.currentInspectCode = code;
        this.subjectCurrentInspectCodeUpdated.next(code);
    }

    getCurrentInspectCodeUpdateListener() {
        return this.subjectCurrentInspectCodeUpdated.asObservable();
    }
}
