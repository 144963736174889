import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class LanguageService {
  // Thông tin cần lưu trữ
  private language: string = "vn";

  // Để tạo và bắt sự kiện khi có cập nhật dữ liệu
  private subjectPostUpdated: Subject<string> = new Subject<string>();

  // Thêm dữ liệu vào
  getLanguage() {
    return this.language;
  }

  // Để các lớp khác lấy service ra và bắt sự kiện
  getLanguageUpdateListener() {
    return this.subjectPostUpdated.asObservable();
  }

  // Để các lớp khác gọi và thay đổi dữ liệu
  setLanguage(newLanguage: string) {
    // Thay đổi dữ liệu
    this.language = newLanguage;
    // Thông báo có thay đổi trong dữ liệu
    this.subjectPostUpdated.next(this.getLanguage());
  }
}
