import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

export interface Employee {
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
    9: string;
    10: string;
    11: string;
    12: string;
    13: string;
    14: string;
    15: string;
    16: string;
    17: string;
    18: string;
    19: string;
    20: string;
    21: string;
    22: string;
    23: string;
    24: string;
    25: string;
    26: string;
    27: string;
    28: string;
    29: string;
    30: string;
    31: string;
    32: string;
    33: string;
    34: string;
    35: string;
    36: string;
    37: string;
    38: string;
    39: string;
    40: string;
    41: string;
    42: string;
    43: string;
    44: string;
    45: string;
    46: string;
    47: string;
    48: string;
    49: string;
    50: string;
    51: string;
    52: string;
    53: string;
    54: string;
    55: string;
    56: string;
    57: string;
    58: string;
    59: string;
    60: string;
    61: string;
    62: string;
    63: string;
    64: string;
    65: string;
    66: string;
    id: string;
    completed: boolean;
}

@Injectable({ providedIn: 'root' })
export class EmployeeService {
    // Thông tin cần lưu trữ
    private employee: Employee[] = [];

    // Để tạo và bắt sự kiện khi có cập nhật dữ liệu
    private subjectPostUpdated: Subject<Employee[]> = new Subject<Employee[]>();

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    // Thêm dữ liệu vào
    getEmployee() {
        return this.employee;
    }

    // Để các lớp khác lấy service ra và bắt sự kiện
    getEmployeeUpdateListener() {
        return this.subjectPostUpdated.asObservable();
    }

    async fetchEmployee(code: string = this.accountService.getCode()) {
        return await new Promise<Employee[]>((resolve, reject) => {
            this.httpClient
                .post<[]>(
                    this.hostnameService.Hostname + '/api/get/employees',
                    { code: code },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    data.sort((e1, e2) => {
                        if (e1['62'] < e2['62']) return -1;
                        if (e1['62'] > e2['62']) return 1;
                        return 0;
                    });
                    this.employee = data;
                    this.subjectPostUpdated.next(data);
                    resolve(data);
                });
        });
    }

    async fetchAllEmployeeByIds(ids: string[]): Promise<Employee[]> {
        return await new Promise<Employee[]>((resolve, reject) => {
            this.httpClient
                .post<[]>(
                    this.hostnameService.Hostname + '/api/employees/get-by-ids',
                    { ids: ids },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    data.sort((e1, e2) => {
                        if (e1['62'] < e2['62']) return -1;
                        if (e1['62'] > e2['62']) return 1;
                        return 0;
                    });
                    this.employee = data;
                    this.subjectPostUpdated.next(data);
                    resolve(data);
                });
        });
    }

    async fetchAllEmployee() {
        return await new Promise<Employee[]>((resolve, reject) => {
            this.httpClient
                .post<[]>(this.hostnameService.Hostname + '/api/get/all-employees', '', { withCredentials: true })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchEmployeeById(id: string) {
        return await new Promise<Employee>((resolve, reject) => {
            this.httpClient
                .post<Employee>(
                    this.hostnameService.Hostname + '/api/employees/get-by-id',
                    { id: id },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchEmployeesByIds(ids: string[]) {
        return await new Promise<Employee[]>((resolve, reject) => {
            this.httpClient
                .post<Employee[]>(
                    this.hostnameService.Hostname + '/api/employees/get-by-ids',
                    { ids: ids },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchEmployeebyCodes(codes: string[]) {
        return await new Promise<Employee[]>((resolve, reject) => {
            this.httpClient
                .post<Employee[]>(
                    this.hostnameService.Hostname + '/api/employees/get-by-codes',
                    { codes: codes },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchTotalEmployee(): Promise<number> {
        return await new Promise<number>((resolve, reject) => {
            this.httpClient
                .post<number>(this.hostnameService.Hostname + '/api/employee/get-total-emplpoyee', '', {
                    withCredentials: true,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async fetchTotalEmployeeByCode(code: string): Promise<number> {
        return await new Promise<number>((resolve, reject) => {
            this.httpClient
                .post<number>(
                    this.hostnameService.Hostname + '/api/employee/get-total-emplpoyee-by-code',
                    {
                        code: code,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async checkExists(ids: string[]): Promise<any[]> {
        return await new Promise<any[]>((resolve, reject) => {
            this.httpClient
                .post<any[]>(
                    this.hostnameService.Hostname + '/api/employee/check-exists-by-ids',
                    {
                        ids: ids,
                    },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    // Để các lớp khác gọi và thay đổi dữ liệu
    setEmployee(newEmployee: Employee[]) {
        // Thay đổi dữ liệu
        this.employee = newEmployee;
        // Thông báo có thay đổi trong dữ liệu
        this.subjectPostUpdated.next(this.getEmployee());
    }

    async addEmployeeToDB(newEmployee: Employee[]) {
        // let data: Employee[] = [];
        // for (let e of newEmployee) {
        //     if (e['2'] && e['3']) data.push(e); // Tên đệm và tên
        // }

        let results = [];

        for (let e of newEmployee) {
            e = standardlizeEmployee(e);
            let postData = {
                source: window.location.href,
                ma_don_vi: this.accountService.getCode(),
                mat_khau_don_vi: '****',
                loai_du_lieu: 'Báo cáo ',
                ky_bao_cao: 'Kì 1',
                lan_bao_cao: 'Lần 1',
                ngay_cap_nhat: String(new Date()),
                code: this.accountService.getCode(),
                2: e['2'],
                3: e['3'],
                4: e['4'],
                5: e['5'],
                6: e['6'],
                7: e['7'],
                8: e['8'],
                9: e['9'],
                10: e['10'],
                11: e['11'],
                12: e['12'],
                13: e['13'],
                14: e['14'],
                15: e['15'],
                16: e['16'],
                17: e['17'],
                18: e['18'],
                19: e['19'],
                20: e['20'],
                21: e['21'],
                22: e['22'],
                23: e['23'],
                24: e['24'],
                25: e['25'],
                26: e['26'],
                27: e['27'],
                28: e['28'],
                29: e['29'],
                30: e['30'],
                31: e['31'],
                32: e['32'],
                33: e['33'],
                34: e['34'],
                35: e['35'],
                36: e['36'],
                37: e['37'],
                38: e['38'],
                39: e['39'],
                40: e['40'],
                41: e['41'],
                42: e['42'],
                43: e['43'],
                44: e['44'],
                45: e['45'],
                46: e['46'],
                47: e['47'],
                48: e['48'],
                49: e['49'],
                50: e['50'],
                51: e['51'],
                52: e['52'],
                53: e['53'],
                54: e['54'],
                55: e['55'],
                56: e['56'],
                57: e['57'],
                58: e['58'],
                59: e['59'],
                60: e['60'],
                61: e['61'],
                62: e['62'],
                63: e['63'],
                64: e['64'],
                65: e['65'],
                66: e['66'],
            };

            //post
            let result = await new Promise((resolve, reject) => {
                this.httpClient
                    .post<string>(this.hostnameService.Hostname + '/api/add/employees', postData)
                    .subscribe((data) => {
                        console.log('[Data: ]', data);
                        if (data != 'FAIL') {
                            e.id = data;
                            this.employee.push(e);
                            console.log('[Employee: ]', this.employee);
                            this.subjectPostUpdated.next(this.getEmployee());
                            // Swal.fire('Added!', 'Your employee has been add to list!', 'success');
                        }
                        resolve(data);
                    });
            });
            results.push(result);
        }

        return results;
    }

    // Delete employee
    async deleteEmployee(id: string): Promise<string> {
        return await new Promise<string>((resolve, reject) => {
            this.httpClient
                .post<string>(this.hostnameService.Hostname + '/api/delete/employees', {
                    id: id,
                })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    // Get employee haven't tested yet
    getEmployeeTestNone() {
        let result: Employee[] = this.employee.filter((e) => {
            return true;
            // (
            //   e["46"] == "" &&
            //   e["47"] == "" &&
            //   e["48"] == "" &&
            //   e["49"] == "" &&
            //   e["50"] == "" &&
            //   e["51"] == "" &&
            //   e["52"] == "" &&
            //   e["53"] == ""
            // );
        });
        return result;
    }

    getEmployeePhase1() {
        let result: Employee[] = this.employee.filter((e) => {
            return e['46'] != '' || e['47'] != '';
        });
        return result;
    }

    getEmployeePhase2() {
        let result: Employee[] = this.employee.filter((e) => {
            return e['48'] != '' || e['49'] != '';
        });
        return result;
    }

    async toPhase1(employee: Employee) {
        return await new Promise<string>((resolve, reject) => {
            this.httpClient
                .post<string>(this.hostnameService.Hostname + '/api/to-pharse/1', {
                    id: employee.id,
                })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    async toPhase2(employee: Employee) {
        // console.log("Employee to phase 2", employee.id);
        return await new Promise<string>((resolve, reject) => {
            this.httpClient
                .post<string>(this.hostnameService.Hostname + '/api/to-pharse/2', {
                    id: employee.id,
                })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    async editVaccinationInformation(data: string[]) {
        let results: string[] = [];
        let n = this.employee.length;
        for (let i = 0; i < n; i += 1) {
            let id = this.employee[i]['id'];

            let result = await new Promise<string>((resolve, reject) => {
                this.httpClient
                    .post<string>(this.hostnameService.Hostname + '/api/edit-vaccination-information', {
                        id: id,
                        '54': data[i][2],
                        '55': data[i][3],
                        '56': data[i][4],
                        '57': data[i][5],
                        '58': data[i][6],
                        '59': data[i][7],
                        '60': data[i][8],
                    })
                    .subscribe(
                        (data) => {
                            resolve(data);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
            });

            results.push(result);
        }

        return results;
    }

    async editLaborContractInformation(data: string[]) {
        let results: string[] = [];
        let n = this.employee.length;
        for (let i = 0; i < n; i += 1) {
            let id = this.employee[i]['id'];

            let result = await new Promise<string>((resolve, reject) => {
                this.httpClient
                    .post<string>(this.hostnameService.Hostname + '/api/edit-labor-contract', {
                        id: id,
                        '26': data[i][2],
                        '27': data[i][3],
                        '28': data[i][4],
                        '29': data[i][5],
                        '30': data[i][6],
                        '31': data[i][7],
                        '32': data[i][8],
                        '33': data[i][9],
                        '34': data[i][10],
                        '35': data[i][11],
                        '36': data[i][12],
                        '37': data[i][13],
                        '38': data[i][14],
                        '39': data[i][15],
                        '40': data[i][16],
                        '41': data[i][17],
                        '42': data[i][18],
                        '43': data[i][19],
                        '44': data[i][20],
                        '45': data[i][21],
                    })
                    .subscribe(
                        (data) => {
                            resolve(data);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
            });

            results.push(result);
        }

        return results;
    }

    async editTestListInformation(data: string[]) {
        let results: string[] = [];
        let n = this.employee.length;
        for (let i = 0; i < n; i += 1) {
            let id = this.employee[i]['id'];

            let result = await new Promise<string>((resolve, reject) => {
                this.httpClient
                    .post<string>(this.hostnameService.Hostname + '/api/edit-test-list', {
                        id: id,
                        '46': data[i][2],
                        '47': data[i][3],
                        '48': data[i][4],
                        '49': data[i][5],
                        '50': data[i][6],
                        '51': data[i][7],
                        '52': data[i][8],
                        '53': data[i][9],
                    })
                    .subscribe(
                        (data) => {
                            resolve(data);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
            });

            results.push(result);
        }

        return results;
    }

    async editEmployeeInfomation(employee: Employee) {
        let id = employee['id'];
        employee = standardlizeEmployee(employee);

        let result = await new Promise<string>((resolve, reject) => {
            this.httpClient
                .post<string>(this.hostnameService.Hostname + '/api/edit-employee', {
                    id: id,
                    '2': employee['2'],
                    '3': employee['3'],
                    '4': employee['4'],
                    '8': employee['8'],
                    '40': employee['40'],
                    '7': employee['7'],
                    '5': employee['5'],
                    '6': employee['6'],

                    '10': employee['10'],
                    '11': employee['11'],
                    '12': employee['12'],
                    '13': employee['13'],
                    '14': employee['14'],
                    '15': employee['15'],
                    '16': employee['16'],
                    '17': employee['17'],
                    '18': employee['18'],
                    '19': employee['19'],
                    '20': employee['20'],
                    '21': employee['21'],
                    '22': employee['22'],
                    '23': employee['23'],
                    '24': employee['24'],
                    '25': employee['25'],

                    '62': employee['62'],
                    '63': employee['63'],
                    '64': employee['64'],
                    '65': employee['65'],
                    '66': employee['66'],
                })
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
        return result;
    }

    extractEmployee() {
        let code = this.accountService.getCode();
        window.location.href = this.hostnameService.Hostname + '/api/extract/employees?code=' + code;
    }

    extractEmployeeByCodes(codes: string[]) {
        let codeString = JSON.stringify(codes);
        window.location.href = this.hostnameService.Hostname + '/api/extract/employees-by-codes?codes=' + codeString;
    }
}

//   Validate data

export function validateEmployee(newEmployee: Employee) {
    if (!newEmployee['2'] || newEmployee['2'] == '') {
        newEmployee['2'] = '';
        Swal.fire('Error', `Wrong Family Name: "${newEmployee['2']}"`, 'error');
        return false;
    }

    if (!newEmployee['3'] || newEmployee['3'] == '') {
        newEmployee['3'] = '';
        Swal.fire('Error', `Wrong Given Name: "${newEmployee['3']}"`, 'error');
        return false;
    }

    if (!newEmployee['62'] || newEmployee['62'] == '') {
        newEmployee['62'] = '';
        Swal.fire('Error', `Wrong Employee Code: "${newEmployee['62']}"`, 'error');
        return false;
    }

    if (!/^0[0-9]{9,10}$/.test(String(newEmployee['7']))) {
        Swal.fire('Error', `Wrong Phone Number: "${newEmployee['7']}"`, 'error');
        return false;
    }

    // console.log("birth\n");
    // if (
    //   !/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(
    //     newEmployee["5"] // Ngày sinh
    //   )
    // )
    //   return false;
    // console.log("Trình độ\n");
    // if (
    //   !(
    //     newEmployee["10"] == "Phổ thông" || //Trình độ
    //     newEmployee["10"] == "Trung cấp" ||
    //     newEmployee["10"] == "Cao đẳng" ||
    //     newEmployee["10"] == "Đại học" ||
    //     newEmployee["10"] == "Thạc sĩ" ||
    //     newEmployee["10"] == "Tiến sĩ"
    //   )
    // )
    //   return false;
    // console.log("Giới tính\n");
    // if (
    //   !(
    //     newEmployee["4"] == "Nam" || // Giới tính
    //     newEmployee["4"] == "Nữ" ||
    //     newEmployee["4"] == "Khác"
    //   )
    // )
    //   return false;
    // console.log("Chức vụ\n");
    // if (
    //   !(
    //     newEmployee["11"] == "Chuyên môn bậc trung" || // Chức vụ
    //     newEmployee["11"] == "Chuyên môn bậc cao" ||
    //     newEmployee["11"] == "Nhà quản lý" ||
    //     newEmployee["11"] == "Khác"
    //   )
    // )
    //   return false;

    return true;
}

export function standardlizeEmployee(e: Employee) {
    e['2'] = e['2'] ? e['2'] : '';
    e['3'] = e['3'] ? e['3'] : '';
    e['4'] = e['4'] ? e['4'] : '';
    e['5'] = e['5'] ? e['5'] : '';
    e['6'] = e['6'] ? e['6'] : '';
    e['7'] = e['7'] ? e['7'] : '';
    e['8'] = e['8'] ? e['8'] : '';
    e['9'] = e['9'] ? e['9'] : '';
    e['10'] = e['10'] ? e['10'] : '';
    e['11'] = e['11'] ? e['11'] : '';
    e['12'] = e['12'] ? e['12'] : '';
    e['13'] = e['13'] ? e['13'] : '';
    e['14'] = e['14'] ? e['14'] : '';
    e['15'] = e['15'] ? e['15'] : '';
    e['16'] = e['16'] ? e['16'] : '';
    e['17'] = e['17'] ? e['17'] : '';
    e['18'] = e['18'] ? e['18'] : '';
    e['19'] = e['19'] ? e['19'] : '';
    e['20'] = e['20'] ? e['20'] : '';
    e['21'] = e['21'] ? e['21'] : '';
    e['22'] = e['22'] ? e['22'] : '';
    e['23'] = e['23'] ? e['23'] : '';
    e['24'] = e['24'] ? e['24'] : '';
    e['25'] = e['25'] ? e['25'] : '';
    e['26'] = e['26'] ? e['26'] : '';
    e['27'] = e['27'] ? e['27'] : '';
    e['28'] = e['28'] ? e['28'] : '';
    e['29'] = e['29'] ? e['29'] : '';
    e['30'] = e['30'] ? e['30'] : '';
    e['31'] = e['31'] ? e['31'] : '';
    e['32'] = e['32'] ? e['32'] : '';
    e['33'] = e['33'] ? e['33'] : '';
    e['34'] = e['34'] ? e['34'] : '';
    e['35'] = e['35'] ? e['35'] : '';
    e['36'] = e['36'] ? e['36'] : '';
    e['37'] = e['37'] ? e['37'] : '';
    e['38'] = e['38'] ? e['38'] : '';
    e['39'] = e['39'] ? e['39'] : '';
    e['40'] = e['40'] ? e['40'] : '';
    e['41'] = e['41'] ? e['41'] : '';
    e['42'] = e['42'] ? e['42'] : '';
    e['43'] = e['43'] ? e['43'] : '';
    e['44'] = e['44'] ? e['44'] : '';
    e['45'] = e['45'] ? e['45'] : '';
    e['46'] = e['46'] ? e['46'] : '';
    e['47'] = e['47'] ? e['47'] : '';
    e['48'] = e['48'] ? e['48'] : '';
    e['49'] = e['49'] ? e['49'] : '';
    e['50'] = e['50'] ? e['50'] : '';
    e['51'] = e['51'] ? e['51'] : '';
    e['52'] = e['52'] ? e['52'] : '';
    e['53'] = e['53'] ? e['53'] : '';
    e['54'] = e['54'] ? e['54'] : '';
    e['55'] = e['55'] ? e['55'] : '';
    e['56'] = e['56'] ? e['56'] : '';
    e['57'] = e['57'] ? e['57'] : '';
    e['58'] = e['58'] ? e['58'] : '';
    e['59'] = e['59'] ? e['59'] : '';
    e['60'] = e['60'] ? e['60'] : '';
    e['61'] = e['61'] ? e['61'] : '';
    e['62'] = e['62'] ? e['62'] : '';
    e['63'] = e['63'] ? e['63'] : '';
    e['64'] = e['64'] ? e['64'] : '';
    e['65'] = e['65'] ? e['65'] : '';
    e['66'] = e['66'] ? e['66'] : '';

    return e;
}
