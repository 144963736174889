import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

export const PHAN_CAP = ['1', '2', '3'];
export const LOAI = ['BÁO CÁO CÔNG TÁC TUYỂN DỤNG, SỬ DỤNG VÀ QUẢN LÝ CÔNG CHỨC, VIÊN CHỨC VÀ NGƯỜI LAO ĐỘNG'];

@Injectable({ providedIn: 'root' })
export class M3Service {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetchInfo(nam: string, loai_bao_cao: string, ma_don_vi: string = '') {
        return await new Promise<any>((resolve, reject) => {
            let metadata: any = {};
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;

            if (!ma_don_vi || ma_don_vi == '') ma_don_vi = metadata.ma_don_vi;

            metadata.loai_bao_cao = loai_bao_cao;
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/read/report-staff-organization/m-3/get',
                    {
                        metadata: metadata,
                        nam: nam,
                        ma_don_vi: ma_don_vi,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async editM3(metadata: any, data: any, position: any) {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getAuthMetadata();
            metadata.ma_don_vi = final_metadata.ma_don_vi;
            metadata.mat_khau_don_vi = final_metadata.mat_khau_don_vi;

            this.httpClient
                .put<any>(
                    this.hostnameService.VnuisAPI + '/api/v1/staging/report-staff-organization/m-3/edit',
                    {
                        metadata: metadata,
                        data: data,
                        position: position,
                    },
                    {
                        headers: { apikey: this.accountService.apikey },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }
}
