import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HostnameService } from './hostname.service';

export interface EmployeeLonlat {
    id: string;
    msnv: string;
    lon: string;
    lat: string;
}

@Injectable({ providedIn: 'root' })
export class EmployeeLonLatServices {
    constructor(private httpClient: HttpClient, private hostnameService: HostnameService) {}

    async fetchAll() {
        return await new Promise<EmployeeLonlat[]>((reslove, reject) => {
            this.httpClient
                .post<EmployeeLonlat[]>(this.hostnameService.Hostname + '/api/get-all-employee-lon-lat', '', {
                    withCredentials: true,
                })
                .subscribe((data) => {
                    reslove(data);
                });
        });
    }

    async fetchOne(msnv: string) {
        return await new Promise<EmployeeLonlat[]>((reslove, reject) => {
            this.httpClient
                .post<EmployeeLonlat[]>(
                    this.hostnameService.Hostname + '/api/get-employee-lon-lat',
                    { msnv: msnv },
                    { withCredentials: true }
                )
                .subscribe((data) => {
                    reslove(data);
                });
        });
    }
}
